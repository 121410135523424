import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { ThemeContext } from 'utils/constants';
import { useDetectIE } from 'hooks';
import { useStaticQuery, graphql } from 'gatsby';
import {
  SEO,
  HomePageLanding,
  HomePageSolutions,
  HomePageInsights,
  CareersCarousel,
  CTA,
} from 'components';
import { homeAnimationIn } from 'threejs/page';

const IndexPage = ({ location: { pathname } }) => {
  const {
    insights,
    homepage: {
      headline,
      subhead,
      topperLeftContent,
      topperRightContent,
      solutionsHeadline,
      solutionsIntro,
      solutionsLink,
      solutionsButton,
      solutionsHeadline2,
      solutionsIntro2,
      solutionsLink2,
      solutionsButton2,
      solutions,
      carouselTitle,
      carouselButtonText,
      carouselLink,
      carousel,
      showRightMenu,
      solutionColumns,
      insightsHeadline,
      featuredInsights,
      cta,
      hideFooterCta,
      seo,
      valueProp,
      content,
      overflowHeroContent,
    },
  } = useStaticQuery(graphql`
    fragment INSIGHT on ContentfulArticle {
      author {
        name
        image {
          title
          file {
            url
          }
        }
      }
      slug
      title
      intro {
        intro
      }
      articleImage {
        description
        title
        file {
          url
        }
      }
      topics {
        slug
        name
        image {
          title
          file {
            url
          }
        }
      }
      date(formatString: "MM.DD.YY")
    }

    fragment HOMEPAGESOLUTIONS on ContentfulHomePageSolutionColumns {
      headline1
      intro1 {
        intro1
      }
      linkType1
      linkText1
      linkUrl1
      headline2
      intro2 {
        intro2
      }
      linkType2
      linkText2
      linkUrl2
      headline3
      intro3 {
        intro3
      }
      linkType3
      linkText3
      linkUrl3
    }

    query HomePageQuery {
      # Set limit to 5 so that worst case difference between
      # all insights and featured insights will be 3
      insights: allContentfulArticle(limit: 5, sort: { fields: date, order: DESC }) {
        edges {
          node {
            ...INSIGHT
          }
        }
      }

      homepage: contentfulHomePage(headline: { ne: "PLACEHOLDER" }) {
        headline
        subhead {
          json
        }
        topperLeftContent {
          intro {
            json
          }
          link
          image {
            title
            description
            file {
              url
            }
          }
        }
        topperRightContent {
          headline
          intro {
            json
          }
          image {
            title
            description
            file {
              url
            }
          }
          format
          link
        }
        solutionsHeadline
        valueProp {
          media {
            title
            description
            id
            file {
              url
            }
          }
          caption {
            json
          }
        }
        content {
          json
        }
        overflowHeroContent {
          media {
            title
            description
            id
            file {
              url
            }
          }
          caption {
            json
          }
          link
          buttonText
          contentful_id
        }
        solutionsHeadline2
        solutionsIntro {
          solutionsIntro
        }
        solutionsIntro2 {
          solutionsIntro2
        }
        solutionsLink
        solutionsLink2
        solutionsButton {
          buttonLabel
          buttonLink
        }
        solutionsButton2 {
          buttonLabel
          buttonLink
        }
        solutions {
          title
          slug
          intro {
            intro
          }
        }
        showRightMenu
        solutionColumns {
          ...HOMEPAGESOLUTIONS
        }
        carouselTitle {
          carouselTitle
        }
        carouselButtonText
        carouselLink
        carousel {
          caption {
            json
          }
          link
          image {
            title
            description
            file {
              url
            }
          }
        }
        insightsHeadline
        featuredInsights {
          ...INSIGHT
        }
        cta {
          ...CTA
        }
        hideFooterCta
        seo {
          ...SEO
        }
      }
    }
  `);
  const context = useContext(ThemeContext);
  const isIE = useDetectIE();
  const serializedInsights = insights.edges.map(({ node }) => node);

  useEffect(() => {
    if (!isIE) {
      homeAnimationIn();
    }
  }, []);

  return (
    <div className={context}>
      <Helmet
        bodyAttributes={{
          class: 'has-animation',
        }}
      />
      <SEO {...seo} pathname={pathname} />
      <HomePageLanding
        headline={headline}
        subhead={subhead}
        topperLeftContent={topperLeftContent}
        topperRightContent={topperRightContent}
      />
      <div id="js-animate-content">
        <HomePageSolutions
          headline={solutionsHeadline}
          intro={solutionsIntro}
          link={solutionsLink}
          button={solutionsButton}
          headline2={solutionsHeadline2}
          intro2={solutionsIntro2}
          link2={solutionsLink2}
          button2={solutionsButton2}
          solutions={solutions}
          valueProp={valueProp}
          content={content}
          overflowHeroContent={overflowHeroContent}
          showRightMenu={showRightMenu}
          solutionColumns={solutionColumns}
        />
        <CareersCarousel
          carouselItems={carousel}
          title={carouselTitle && carouselTitle.carouselTitle}
          buttonText={carouselButtonText}
          buttonLink={carouselLink}
          name={carouselTitle.carouselTitle}
        />
        <HomePageInsights
          headline={insightsHeadline}
          featuredInsights={featuredInsights}
          insights={serializedInsights}
        />
        {!hideFooterCta && <CTA {...cta} showWhiteArea onFooter />}
      </div>
    </div>
  );
};

IndexPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default IndexPage;
